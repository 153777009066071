import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import { LanguageContext } from "../../../context/languageContext"

import DesktopSubmenu from "./desktop-submenu"

import flagEnglish from "../../../assets/img/common/flag_english.png"
import flagIndonesia from "../../../assets/img/common/flag_indonesia.png"

const DesktopMenu = ({ navExpand, megaMenu, setMegaMenu }) => {
  const { lang, setLang } = useContext(LanguageContext)

  // DESKTOP SUBMENU - State & handler
  // Open / close submenu state & handler
  const [desktopSubmenu, setDesktopSubmenu] = useState({
    initial: false,
    clicked: null,
  })

  const handleDesktopSubmenu = () => {
    disableSubmenuToggler()
    if (desktopSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: true })
    } else if (desktopSubmenu.clicked === true) {
      setDesktopSubmenu({ clicked: !desktopSubmenu.clicked })
    } else if (desktopSubmenu.clicked === false) {
      setDesktopSubmenu({ clicked: !desktopSubmenu.clicked })
    }
  }

  // Toggle submenu toggler disabled state & handler
  const [submenuToggler, setSubmenuToggler] = useState(false)
  const disableSubmenuToggler = () => {
    setSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setSubmenuToggler(false)
    }, 1000)
  }

  // const flag = () => {
  //   if (lang === "en") {
  //     return flagEnglish
  //   } else if (lang === "id") {
  //     return flagIndonesia
  //   } else {
  //     return flagEnglish
  //   }
  // }

  // const switchLanguage = () => {
  //   if (lang === "en") {
  //     setLang("id")
  //   } else if (lang === "id") {
  //     setLang("en")
  //   }
  // }

  return (
    <>
      <div className={`navbar-menu light d-${navExpand}-block`}>
        <div className="navbar-menu-bg"></div>
        <div className="navbar-menu-content">
          <div className="navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <div>
                  {/* prettier-ignore */}
                  <Link className="nav-link fx-underline" activeClassName="active" to="/work" activeStyle={{ fontWeight: "bold" }} >
                    Work
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div>
                  {/* prettier-ignore */}
                  <Link className="nav-link fx-underline" activeClassName="active" to="/about" activeStyle={{ fontWeight: "bold" }} >
                    About
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div>
                  {/* prettier-ignore */}
                  <Link className="nav-link fx-underline" activeClassName="active" to="/contact" activeStyle={{ fontWeight: "bold" }} >
                    Contact
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DesktopSubmenu
        desktopSubmenu={desktopSubmenu}
        handleDesktopSubmenu={handleDesktopSubmenu}
        navExpand={navExpand}
      />
    </>
  )
}

export default DesktopMenu
