import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

import { SocialLinks } from "../../anti/social-links/social-links"

import {
  staggerBackgroundIn,
  staggerBackgroundOut,
  staggerContentIn,
  staggerContentOut,
} from "./animations"
import MobileSubmenu from "./mobile-submenu"

const MobileMenu = ({ mobileMenu, navExpand }) => {
  // MOBILE SUBMENU - State & handler
  // Open / close submenu state & handler
  const [mobileSubmenu, setMobileSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const handleMobileSubmenu = () => {
    disableSubmenuToggler()
    if (mobileSubmenu.initial === false) {
      setMobileSubmenu({ initial: null, clicked: true })
    } else if (mobileSubmenu.clicked === true) {
      setMobileSubmenu({ clicked: !mobileSubmenu.clicked })
    } else if (mobileSubmenu.clicked === false) {
      setMobileSubmenu({ clicked: !mobileSubmenu.clicked })
    }
  }
  // Toggle submenu toggler disabled state & handler
  const [submenuToggler, setSubmenuToggler] = useState(false)
  const disableSubmenuToggler = () => {
    setSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setSubmenuToggler(false)
    }, 1000)
  }

  // MOBILE MENU - Animation
  // Element ref.
  let menuWrapper = useRef(null) // Reveal 1
  let menuBackground = useRef(null) // Reveal 2
  let menuContent = useRef(null) // Reveal 3
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  // Reveal & close animation
  useEffect(() => {
    const navbarToggler = document.querySelector(".navbar-toggler")
    if (mobileMenu.clicked === false) {
      staggerContentOut(line4, line3, line2, line1)
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
      navbarToggler.classList.remove("open", "dark")
    } else if (
      mobileMenu.clicked === true ||
      (mobileMenu.clicked === true && mobileMenu.initial === null)
    ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
      staggerContentIn(line1, line2, line3, line4)
      navbarToggler.classList.add("open", "light")
    }
  }, [mobileMenu])

  return (
    <>
      <div
        className={`navbar-menu dark d-${navExpand}-none`}
        ref={el => (menuWrapper = el)}
      >
        <div className="navbar-menu-bg" ref={el => (menuBackground = el)}></div>
        <div className="navbar-menu-content" ref={el => (menuContent = el)}>
          <div className="navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <div ref={el => (line1 = el)}>
                  {/* prettier-ignore */}
                  <Link className="nav-link h2" activeClassName="active" to="/work" >
                    Work
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div ref={el => (line2 = el)}>
                  {/* prettier-ignore */}
                  <Link className="nav-link h2" activeClassName="active" to="/about" >
                    About
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div ref={el => (line3 = el)}>
                  {/* prettier-ignore */}
                  <Link className="nav-link h2" activeClassName="active" to="/contact" >
                    Contact
                  </Link>
                </div>
              </li>
            </ul>
            <div className="social-links-wrapper">
              <div ref={el => (line4 = el)}>
                <SocialLinks
                  data={[
                    {
                      iconName: "fa-instagram",
                      url: "https://www.instagram.com/fostivevisual/",
                    },
                    {
                      iconName: "fa-youtube",
                      url:
                        "https://www.youtube.com/channel/UC9wocmU41cPkY_ulo1gRc4g",
                    },
                    {
                      iconName: "fa-behance",
                      url: "https://www.behance.net/fostivevisual",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileSubmenu
        mobileSubmenu={mobileSubmenu}
        handleMobileSubmenu={handleMobileSubmenu}
        navExpand={navExpand}
      />
    </>
  )
}

export default MobileMenu
