import React from "react"
import PropTypes from "prop-types"

export const SocialLinks = ({ data, className }) => {
  return (
    <ul className={`social-links ${className}`}>
      {data.map((item, i) => {
        return (
          <li className="social-item" key={i}>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <i className={`fab ${item.iconName}`}></i>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

SocialLinks.propTypes = {
  className: PropTypes.string, // Set optional classes
}

SocialLinks.defaultProps = {
  className: "",
}
