import React from "react"
import { Link } from "gatsby"

import { SocialLinks } from "../../anti/social-links/social-links"

const Footer = () => {
  const quickLinks = [
    { title: "About Us", link: "/about" },
    { title: "Investor's Area", link: "/investors-area" },
    { title: "Access", link: "/access" },
    { title: "Sitemap", link: "/sitemap" },
    { title: "Blog", link: "/blog" },
  ]
  return (
    <footer>
      <div className="footer-top py-main">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-7 col-xl-6 order-md-lst mb-sm-down-4">
              <div className="footer-col footer-newsletter">
                <h5>Newsletter</h5>
                <p>
                  Subscribe to our newsletter now to get the latest news from
                  BSD City.
                </p>
                <form>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter email address"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-secondary" type="button">
                        <i className="far fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form>
                <Copyright className="d-none d-md-block" />
              </div>
            </div>
            <div className="col-md-4 col-lg-5 col-xl-6 order-md-first">
              <div className="footer-col">
                <h5>Quick Links</h5>
                <ul className="footer-nav">
                  {quickLinks.map((quickLink, i) => {
                    return (
                      <li className="footer-item" key={i}>
                        <Link className="footer-link" to={quickLink.link}>
                          {quickLink.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
                <SocialLinks
                  data={[
                    { iconName: "fa-facebook-square", url: "#" },
                    { iconName: "fa-twitter-square", url: "#" },
                    { iconName: "fa-instagram", url: "#" },
                  ]}
                  className="mt-3"
                />
                <Copyright className="d-block d-md-none" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const Copyright = ({ className }) => {
  return (
    <div className={`footer-copyright ${className}`}>
      © 2020 BSD City. &nbsp;
      <Link to="/privacy-policy">Privacy Policy</Link>
      <span className="divider">|</span>
      <Link to="/terms-conditions">Terms & Conditions</Link>
    </div>
  )
}
