import React, { useEffect, useRef } from "react"

import { staggerBackgroundIn, staggerBackgroundOut } from "./animations"
import { Card } from "../../anti/card/card"
import { Link } from "../../anti/utils/utils"

import imgSample169a from "../../../assets/img/sample/img_sample_16-9-a.jpg"
import imgSample169b from "../../../assets/img/sample/img_sample_16-9-b.jpg"
import imgSample169c from "../../../assets/img/sample/img_sample_16-9-c.jpg"
import imgSample169d from "../../../assets/img/sample/img_sample_16-9-d.jpg"
import imgSample169e from "../../../assets/img/sample/img_sample_16-9-e.jpg"

const MobileSubmenu = ({ mobileSubmenu, handleMobileSubmenu, navExpand }) => {
  // MOBILE SUBMENU
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)

  // Reveal & close animation
  // prettier-ignore
  useEffect(() => {
    if (mobileSubmenu.clicked === false) {
      staggerBackgroundOut(menuContent, menuBackground, menuWrapper)
    } else if ( mobileSubmenu.clicked === true || (mobileSubmenu.clicked === true && mobileSubmenu.initial === null) ) {
      staggerBackgroundIn(menuWrapper, menuBackground, menuContent)
    }
  }, [mobileSubmenu])

  // DATA
  const submenus = [
    { title: "Submenu 1", link: "/" },
    { title: "Submenu 2", link: "/" },
    { title: "Submenu 3", link: "/" },
    { title: "Submenu 4", link: "/" },
  ]
  // prettier-ignore
  const categories = [
    { title: "Category A", img: imgSample169a, link: "/" },
    { title: "Category B", img: imgSample169b, link: "/" },
    { title: "Category C", img: imgSample169c, link: "/" },
    { title: "Category D", img: imgSample169d, link: "/" },
    { title: "Category E", img: imgSample169e, link: "/" },
  ]

  return (
    <>
      <div className={`d-block d-${navExpand}-none`}>
        <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
          {/* prettier-ignore  */}
          <div className="navbar-submenu-bg" ref={el => (menuBackground = el)} onClick={handleMobileSubmenu} />
          {/* prettier-ignore  */}
          <div className="navbar-submenu-content" ref={el => (menuContent = el)}>
            <div className="navbar-submenu-collapse">
              <div className="navbar-submenu-collapse-header">
                <button className="btn btn-link" onClick={handleMobileSubmenu}>
                  Back
                </button>
              </div>
              <div className="navbar-submenu-collapse-content">
                <div className="overflow-hidden">
                  <h2>Service</h2>
                </div>
                <div className="overflow-hidden">
                  <p>Lorem ipsum dolor siamet</p>
                </div>
                <ul className="navbar-nav">
                  {submenus.map((submenu, i) => {
                    return (
                      <li className="nav-item" key={`${submenu.title}-${i}`}>
                        <div>
                          <Link
                            className="nav-link"
                            activeClassName="active"
                            to={submenu.link}
                          >
                            {submenu.title}
                          </Link>
                        </div>
                      </li>
                    )
                  })}
                </ul>
                <div className="submenu-card-list">
                  {categories.map((category, i) => {
                    return (
                      <Card
                        variant="overlay"
                        img={category.img}
                        imgHeight="h-ratio-2-1"
                        title={category.title}
                        titleClassName="h5"
                        link={category.link}
                        className="mb-3"
                        key={`${category.title}-${i}`}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileSubmenu
