import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"

import { revealFromTop, revealCloseY } from "./animations"
import { Card } from "../../anti/card/card"
import { Link } from "../../anti/utils/utils"

import imgSample169a from "../../../assets/img/sample/img_sample_16-9-a.jpg"
import imgSample169b from "../../../assets/img/sample/img_sample_16-9-b.jpg"
import imgSample169c from "../../../assets/img/sample/img_sample_16-9-c.jpg"

const DesktopSubmenu = ({
  desktopSubmenu,
  handleDesktopSubmenu,
  navExpand,
}) => {
  // MOBILE SUBMENU - Animation
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)

  // prettier-ignore
  useEffect(() => {
    if (desktopSubmenu.clicked === false) {
      revealCloseY(menuContent)
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } })
    } else if ( desktopSubmenu.clicked === true || (desktopSubmenu.clicked === true && desktopSubmenu.initial === null) ) {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(menuContent, { duration: 0, opacity: 1, height: "auto" })
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(menuContent)
    }
  }, [desktopSubmenu])

  // DATA
  const submenus = [
    { title: "Submenu 1", link: "/" },
    { title: "Submenu 2", link: "/" },
    { title: "Submenu 3", link: "/" },
    { title: "Submenu 4", link: "/" },
  ]
  // prettier-ignore
  const categories = [
    { label: "New!", title: "South Gate", text: "Apartment", img: imgSample169a },
    { label: "", title: "Taman Tekno", text: "Apartment", img: imgSample169b },
    { label: "", title: "Foresta", text: "Apartment", img: imgSample169c },
  ]

  return (
    <>
      <div className={`d-none d-${navExpand}-block`}>
        <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
          {/* prettier-ignore  */}
          <div className="navbar-submenu-bg" ref={el => (menuBackground = el)} onClick={handleDesktopSubmenu} />
          {/* prettier-ignore  */}
          <div className="navbar-submenu-content" ref={el => (menuContent = el)} >
            <div className="navbar-submenu-collapse">
              {/* <div className="navbar-submenu-collapse-header">
                <button className="btn btn-link" onClick={handleDesktopSubmenu}>
                  Back
                </button>
              </div> */}
              <div className="navbar-submenu-collapse-content">
                <div className="row row-3 w-100">
                  <div className="col-md-4">
                    <h2>Services</h2>
                    <p>Lorem ipsum dolor siamet</p>
                    <ul className="navbar-nav">
                      {submenus.map((submenu, i) => {
                        return (
                          <li className="nav-item" key={i}>
                            <div>
                              <Link
                                className="nav-link"
                                activeClassName="active"
                                to={submenu.link}
                              >
                                {submenu.title}
                              </Link>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <div className="d-none d-lg-block">
                      <div className="row">
                        {categories.map((category, i) => {
                          return (
                            <div className="col-md-4" key={i}>
                              <Card
                                variant="overlay"
                                img={category.img}
                                label={category.label}
                                title={category.title}
                                titleClassName="h5"
                                text={category.text}
                                imgHeight="h-ratio-1-1"
                                className="card-property"
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopSubmenu
