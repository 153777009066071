import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"

// import logoNavbarDark from "assets/img/brand/logo_anti-dark.png"
import logoNavbarLight from "assets/img/brand/logo_fostive_white.png"

const Navbar = ({ background }) => {
  // Onscroll
  const [navScroll, setNavScroll] = useState(false)
  useEffect(() => {
    const navbar = document.querySelector(".navbar")
    const containsClass = navbar.classList.contains("light")

    window.onscroll = function() {
      if (!containsClass) {
        if (window.pageYOffset > 40) {
          setNavScroll(true)
        } else {
          setNavScroll(false)
        }
      }
    }
  }, [])

  // Navbar expand config
  // Change the const below & config on _navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl
  const navExpand = "lg"

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })
  const [megaMenu, setMegaMenu] = useState(false)
  const handleMenuMobile = () => {
    disabledMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }
  // Toggler disabled state & handler
  const [disabled, setDisabled] = useState(false)
  const disabledMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1000)
  }
  return (
    <>
      <div
        className="navbar-wrapper fixed-top"
        style={{ backgroundColor: background }}
      >
        {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
        <nav
          className={`navbar navbar-expand-${navExpand} transparent dark 
          ${megaMenu || navScroll ? "is-scrolled" : ""}`}
        >
          <div className="container-fluid position-relative">
            <Link className="navbar-brand" to="/">
              <img
                src={logoNavbarLight}
                className="img-fluid"
                alt="Logo"
                width="auto"
              />
            </Link>
            <button
              className="navbar-toggler"
              disabled={disabled}
              onClick={handleMenuMobile}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <DesktopMenu
              navExpand={navExpand}
              megaMenu={megaMenu}
              setMegaMenu={setMegaMenu}
            />
            <MobileMenu mobileMenu={mobileMenu} navExpand={navExpand} />
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navbar
